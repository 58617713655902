<template>
 <div :class="{'light': bright, 'zIndex': isOpen }" class="autocomplete">
		<md-field md-clearable
			@md-clear="cleared"
		>
			<label for="title" class="form-label">{{ label }} </label>
			<md-input
				@input="onChange"
				@click="onChange"
				v-model="search"
				@keyup.down="onArrowDown"
				@keyup.up="onArrowUp"
				@keyup.enter="onEnter"
				:placeholder="plHolder"
			></md-input>
		</md-field>
		<div class="results-container" :class="{'zIndex': isOpen }">
			<ul id="autocomplete-results" v-show="isOpen" class="autocomplete-results">
				<li class="loading" v-if="isLoading">
					Loading results...
				</li>
				<li v-else
					v-for="(result, i) in results"
					:key="i"
					@click="setResult(result)"
					class="autocomplete-result"
					:class="{ 'is-active': i === arrowCounter }"
				>
					{{ result }}
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
import { searchMixin } from '@/mixins/searchMixin'

export default {
	name: 'autocomplete',
	mixins: [searchMixin],

	props: {
		label: {
			type: String,
			required: true,
			default: () => ""
		},
		plHolder: {
			type: String,
			required: false,
			default: () => ""
		},
		items: {
			type: Array,
			required: false,
			default: () => []
		},
		bright: {
			type: Boolean,
			required: false,
			default: false
		}
	},

	data() {
		return {
			isOpen: false,
			results: [' '],
			search: "",
			isLoading: false,
			arrowCounter: 0
		}
	},

	methods: {
		cleared() {
			this.$emit('cleared', '')
		},

		onChange() {
			// Let's warn the parent that a change was made
			this.$emit("input", this.search)

			// Is the data given by an outside ajax request?
			if (this.isAsync) {
				this.isLoading = true
			} else {
				// Let's search our flat array
				this.filterResults()
				this.isOpen = true
			}
		},

		filterResults() {
			// first uncapitalize all the things
			this.results = this.items.filter(item => {
				if (item.length > 0) {
					return item.toLowerCase().indexOf(this.search.toLowerCase()) > -1
				}
				return ""
			})
		},
		setResult(result) {
			this.search = result
			this.onSelection()
			this.isOpen = false
		},
		onSelection() {
			this.$emit('selectionMade', this.search)
		},
		onArrowDown(evt) {
			if (this.arrowCounter < this.results.length) {
				this.arrowCounter = this.arrowCounter + 1
			}
		},
		onArrowUp() {
			if (this.arrowCounter > 0) {
				this.arrowCounter = this.arrowCounter - 1
			}
		},
		onEnter() {
			this.search = this.results[this.arrowCounter]
			this.isOpen = false
			this.arrowCounter = -1
			this.onSelection()
		},
		handleClickOutside(evt) {
			if (!this.$el.contains(evt.target)) {
				this.isOpen = false
				this.arrowCounter = -1
			}
		}
	},
	watch: {
		items: function(val, oldValue) {
			// actually compare them
			if (val.length !== oldValue.length) {
				this.results = val
				this.isLoading = false
			}
		}
	},
	created() {
		if (this.plHolder) {
			this.search = this.plHolder
		}
	},
	beforeMount() {
		this.$emit('ready')
	},
	mounted() {
		document.addEventListener("click", this.handleClickOutside)
	},
	destroyed() {
		document.removeEventListener("click", this.handleClickOutside)
	}
}
</script>
<style lang="scss" scoped>

.autocomplete {
	position: relative;
  width: 16%;
}

.autocomplete-results {
  padding: 0;
  margin: 0;
  // border: 1px solid #eeeeee;
	-webkit-box-shadow: 0px 13px 14px -3px rgba(8,22,47,1);
	-moz-box-shadow: 0px 13px 14px -3px rgba(8,22,47,1);
	box-shadow: 0px 13px 14px -3px rgba(8,22,47,1);
	height: auto;
	max-height: 130px;
  overflow: auto;
	width: 100%;
	max-width: 300px;
}

.results-container {
	margin-top: -22px;
}

.autocomplete-result {
  list-style: none;
  text-align: left;
  padding: 8px 2px 4px 8px;
  cursor: pointer;
	background-color: $blue;
	color:#fff;
}

.zIndex {
	z-index: 100000;
	z-index: 1000!important;
}

.autocomplete-result.is-active,
.autocomplete-result:hover {
  background-color: #fff;
  color: $blue;
}

.form-label {
	color: #fff!important;
	padding-left: 3%;
	font-size: calc(11px + (18 - 11) * ((100vw - 300px) / (1600 - 300)));
}

.md-field.md-theme-default.md-has-value .md-input {
	-webkit-text-fill-color:#fff!important;
}

$shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16), 0 2px 8px 0 rgba(0, 0, 0, 0.12);
.drop {
	// position: relative;
	ul {
		position: absolute;
		left: 0;
		top: 3em;
		transition: all 0.3s ease;
		transform: scale(0);
		transform-origin: 0 0;
		box-shadow: $shadow;
		background-color: #fff;
		li {
			display: block;
			background-color: #fff;
			width: 100%;
			a {
				width: 100%;
				padding: 1em 18px;
				display: inline-block;
				white-space: pre;
				box-sizing: border-box;
				background: #fff;
				&:hover {
					background: lighten($grey, 30%);

				}
			}
		}
	}
	&:hover {
		ul {
			transform: scale(1);
		}
	}
}
.autocomplete {
	position: absolute;
}

.light {
	width: 19%;

	.autocomplete-results {
		-webkit-box-shadow: 0px 5px 14px 0px rgba(114,114,114,1);
		-moz-box-shadow: 0px 5px 14px 0px rgba(114,114,114,1);
		box-shadow: 0px 5px 14px 0px rgba(114,114,114,1);
	}

	.autocomplete-result {
		background-color: #fff;
		color:#000;
	}

	.autocomplete-result.is-active,
	.autocomplete-result:hover {
		// background-color: $blue;
		background-color: #414042;
		color: #fff;
	}

	.form-label {
		color: $grey!important;
	}

	.md-field.md-theme-default.md-has-value .md-input {
		-webkit-text-fill-color:#000!important;
	}
}

@media(max-width: 1350px) {
	.autocomplete {
		position: relative;
		width: 100%;
	}

	.results-container {
		position: absolute;
	}
}

@media(max-width: 1100px) {
	.form-label {
		padding-left: 0%;
	}
}

@media(max-width: 620px) {
	.results-container {
		min-width: 80%;
	}
}

</style>
