<template>
	<div class="mobile-filters">
			<Filters />
	</div>
</template>

<script>

import Filters from "@/components/Filters.vue"
export default {
	name: "MobileFilters",
	components: { Filters },
	props: {
		isShowing: {
			type: Boolean,
			default: false
		}
	}
}
</script>

<style lang="scss" scoped>

</style>
