<template>
	<div class="contact-page">
		<Contact />
	</div>
</template>

<script>
import Contact from '@/components/Contact.vue'
export default {
	name: 'ContactPage',
	components: {
		Contact
	}

}
</script>

<style lang="scss" scoped>
	.contact-page {
    height: 80vh;
    display: grid;
    justify-items: center;
	}
</style>
