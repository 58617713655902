<template>
	<div class="contact-container">
		<div class="page-info">
			<div class="section-title">
				<h2>Επικοινωνία</h2>
			</div>
			<div class="info-container">
				<div class="laskaridis-logo"></div>
				<div class="info">
					<img src="@/assets/img/logo_GR.png" alt="" class="logo">
					<ul>
						<li>Πραξιτέλους 169 & Μπουμπουλίνας,<br/> 185 35 Πειραιάς</li>
						<li>Τ: 216 9003700</li>
					</ul>
				</div>
				<div class="form-container">
					<ContactForm />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import ContactForm from '@/components/ContactForm'

export default {
	name: "Contact",
	components: {
		ContactForm
	}
}
</script>

<style scoped lang="scss">
	.contact-container {
		padding: 5% 18%;
		background-image: url('../assets/img/photo_books_4.png');
		background-size: contain;
		background-repeat: no-repeat;
		background-position:  right;
		width: 100%;
    max-width: 2560px;

		.page-info {
			max-width: 70rem;
		}
	}

	.section-title {
		width: 100%;
		margin-bottom: 7%;
		h2 {
					font-size: calc(14px + (45 - 14) * ((100vw - 300px) / (1600 - 300)));
					text-align: left;
		}
	}

	.info-container {
		display: flex;

		.logo {
			margin-bottom: 7%;
			width: 57%;
		}

		.info {
			display: flex;
			flex-direction: column;

			ul {
				list-style-type: none;
				text-align: left;
			}

			li + li {
				margin: 1% 0;
			}

			li:first-child,
			li:last-child {
				margin: 5% 0;
			}
		}
		.form-container {
			flex-grow: 1;
			padding: 0 6%;
		}
	}

	@media (max-width: 770px) {
		.info-container {
			flex-direction: column;

			.logo {
				width: 40%;
			}
		}
}

	@media(max-width: 560px) {
		.contact-container {
			padding: 5%;
		}
	}

</style>
